import { Injectable } from '@angular/core';
import { MetricsService } from '@dougs/core/metrics';
import { EcommerceSaleCreate, PaymentProcessorInfo, SalesChannel } from '@dougs/ecommerce/dto';
import { Operation, OperationTrackingParams } from '@dougs/operations/dto';

@Injectable({ providedIn: 'root' })
export class OperationEcommerceMetricsService {
  constructor(private readonly metricsService: MetricsService) {}

  private generateEcommerceGenericTrackingParams(
    operation: Operation,
    salesChannel: SalesChannel,
  ): OperationTrackingParams {
    return {
      'Operation Id': operation.id,
      'Operation Date': operation.date,
      'Sale Channel Category': salesChannel.category,
      'Sale Channel Type': salesChannel.type,
    };
  }

  public trackEcommerceOperationOpened(operation: Operation, salesChannel?: SalesChannel): void {
    if (operation.type === 'dispatch:ecommerce:salesChannel' && salesChannel) {
      const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(
        operation,
        salesChannel,
      );

      this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch opened', {
        isValidated: operation.validated,
        ...genericParams,
      });
    }
  }

  public trackEcommerceOperationValidated(operation: Operation): void {
    if (operation.type === 'dispatch:ecommerce:salesChannel') {
      this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch validated', {
        'Operation Date': operation.date,
        'Operation Id': operation.id,
        'Operation Amount': operation.amount,
        'Operation Label': operation.name,
      });
    }
  }

  public trackEcommerceOperationDispatchOriginLocationClicked(
    CTALocation: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }

    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch origin location button clicked', {
      'CTA Location': CTALocation,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchOriginLocationClosed(
    CTALocation: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch origin location modal closed', {
      'CTA Location': CTALocation,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchOriginLocationSelected(
    CTALocation: string,
    countryCode: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch origin location selected', {
      'CTA Location': CTALocation,
      'Country Code': countryCode,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchRefreshClicked(operation: Operation, salesChannel?: SalesChannel): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch refresh button clicked', {
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchAddSalesRevenueClicked(
    CTALocationOriginLocation: string,
    CTALocationSalesType: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch add sales revenue button clicked', {
      'CTA Location origin location': CTALocationOriginLocation,
      'CTA Location Sales Type': CTALocationSalesType,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchCountryTaxModalClosed(
    CTALocationOriginLocation: string,
    CTALocationSalesType: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch country tax rate modal closed', {
      'CTA Location origin location': CTALocationOriginLocation,
      'CTA Location Sales Type': CTALocationSalesType,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchCountryTaxModalSelected(
    CTALocationOriginLocation: string,
    CTALocationSalesType: string,
    taxRate: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch country tax rate selected', {
      'CTA Location origin location': CTALocationOriginLocation,
      'CTA Location Sales Type': CTALocationSalesType,
      'Taxe Rate': taxRate,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchPackagePriceModalClosed(
    CTALocationOriginLocation: string,
    CTALocationSalesType: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch package price modal closed', {
      'CTA Location origin location': CTALocationOriginLocation,
      'CTA Location Sales Type': CTALocationSalesType,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchPackagePriceModalSelected(
    CTALocationOriginLocation: string,
    CTALocationSalesType: string,
    packagePrice: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch package price selected', {
      'CTA Location origin location': CTALocationOriginLocation,
      'CTA Location Sales Type': CTALocationSalesType,
      'package price': packagePrice,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchExonerationSelected(
    CTALocationOriginLocation: string,
    CTALocationSalesType: string,
    exemptionReason: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch exoneration reason selected', {
      'CTA Location origin location': CTALocationOriginLocation,
      'CTA Location Sales Type': CTALocationSalesType,
      'Exoneration reason': exemptionReason,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchExonerationModalClosed(
    CTALocationOriginLocation: string,
    CTALocationSalesType: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch exoneration reason modal closed', {
      'CTA Location origin location': CTALocationOriginLocation,
      'CTA Location Sales Type': CTALocationSalesType,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchOriginLocationRemoved(
    CTALocationOriginLocation: string,
    CTALocationSalesType: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch origin location removed', {
      'CTA Location origin location': CTALocationOriginLocation,
      'CTA Location Sales Type': CTALocationSalesType,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchRemoveSalesRevenueButtonClicked(
    CTALocationOriginLocation: string,
    CTALocationSalesType: string,
    CTALocationSalesRevenue: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch remove sales revenue button clicked', {
      'CTA Location origin location': CTALocationOriginLocation,
      'CTA Location Sales Type': CTALocationSalesType,
      'CTA Location sales revenue': CTALocationSalesRevenue,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchSalesRevenueRemoved(
    CTALocationOriginLocation: string,
    CTALocationSalesType: string,
    CTALocationSalesRevenue: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch sales revenue removed', {
      'CTA Location origin location': CTALocationOriginLocation,
      'CTA Location Sales Type': CTALocationSalesType,
      'CTA Location sales revenue': CTALocationSalesRevenue,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchAddCommissionButtonClicked(
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch add commission button clicked', {
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchAddCommissionModalClosed(
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch commission modal closed', {
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchCommissionSelected(
    paymentProcessor: EcommerceSaleCreate,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch commission selected', {
      'Payment Processors Id': paymentProcessor.paymentProcessorId ?? 'unknown',
      'Payment Processors Label': paymentProcessor.label ?? 'unknown',
      'Payement Processors subLabel': paymentProcessor.subLabel ?? 'unknown',
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchAddExpenseButtonClicked(
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch add expense button clicked', {
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchExpenseTypeModalClosed(
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch expense type modal closed', {
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchExpenseTypeSelected(
    expense: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch expense type selected', {
      'FRAIS DIVERS Type': expense,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchSpecificVatRateReasonModalClosed(
    operation: Operation,
    salesChannel: SalesChannel,
    salesType: string,
    originLocation: string,
  ): void {
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);
    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch country tax rate reason modal closed', {
      'CTA Location origin location': originLocation,
      'CTA Location Sales Type': salesType,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchSpecificVatRateReasonSelected(
    specificVatRateReason: string,
    operation: Operation,
    salesChannel: SalesChannel,
    salesType: string,
    originLocation: string,
  ): void {
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);
    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch country tax rate reason selected', {
      'CTA Location origin location': originLocation,
      'CTA Location Sales Type': salesType,
      Reason: specificVatRateReason,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchExpensePaymentProcessorsModalClosed(
    expenseType: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch expense payment processors modal closed', {
      'Expense Type': expenseType,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchExpensePaymentProcessorsSelected(
    expenseType: string,
    paymentProcessor: PaymentProcessorInfo,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch expense payment processors selected', {
      'Expense Type': expenseType,
      'Payment Processors Id': paymentProcessor.paymentsProcessorId,
      'Payment Processors Label': paymentProcessor.label,
      'Payment Processors subLabel': paymentProcessor.subLabel,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchFilesAttached(
    filenames: string[],
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch files attached', {
      Files: filenames,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchFilesRemoved(
    filename: string,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch files removed', {
      File: filename,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchModalResearched(
    CTALocation: string,
    CTALocationOriginLocation: string,
    researchKeyword: string,
    result: number,
    operation: Operation,
    salesChannel?: SalesChannel,
  ): void {
    if (!salesChannel) {
      return;
    }
    const genericParams: OperationTrackingParams = this.generateEcommerceGenericTrackingParams(operation, salesChannel);

    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch files removed', {
      'CTA Location': CTALocation,
      'CTA Location origin location': CTALocationOriginLocation,
      'Research Keyword': researchKeyword,
      Result: result,
      ...genericParams,
    });
  }

  public trackEcommerceOperationDispatchSelectPackageButtonClicked(
    CTALocation: string,
    CTALocationOriginLocation: string,
    operation: Operation,
    salesChannel?: SalesChannel,
    packageId?: string,
    saleId?: string,
  ): void {
    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch package price selected', {
      'CTA Location': CTALocation,
      'CTA Location origin location': CTALocationOriginLocation,
      'package price': packageId,
      'destination countryCode': saleId,
      ...(salesChannel ? this.generateEcommerceGenericTrackingParams(operation, salesChannel) : {}),
    });
  }

  public trackEcommerceDispatchResetClicked(operation: Operation, salesChannel?: SalesChannel): void {
    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch reset button clicked', {
      ...(salesChannel ? this.generateEcommerceGenericTrackingParams(operation, salesChannel) : {}),
    });
  }

  public trackEcommerceDispatchResetModalClosed(operation: Operation, salesChannel?: SalesChannel): void {
    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch remove reset modal closed', {
      ...(salesChannel ? this.generateEcommerceGenericTrackingParams(operation, salesChannel) : {}),
    });
  }

  public trackEcommerceDispatchResetModalValidated(operation: Operation, salesChannel?: SalesChannel): void {
    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch reset modal validated', {
      ...(salesChannel ? this.generateEcommerceGenericTrackingParams(operation, salesChannel) : {}),
    });
  }

  public trackEcommerceResourceClicked(operation: Operation, salesChannel?: SalesChannel): void {
    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch sales channel type helper clicked', {
      ...(salesChannel ? this.generateEcommerceGenericTrackingParams(operation, salesChannel) : {}),
    });
  }

  public trackEcommerceDispatchSectionHelperClicked(
    action: string,
    operation: Operation,
    sectionId?: string,
    salesChannel?: SalesChannel,
  ) {
    this.metricsService.pushMixpanelEvent('Accounting e-commerce dispatch section helper clicked', {
      'CTA Location': sectionId,
      action,
      ...(salesChannel ? this.generateEcommerceGenericTrackingParams(operation, salesChannel) : {}),
    });
  }

  public trackEcommerceDispatchToSynchronizeClicked(salesChannel: SalesChannel, operation: Operation): void {
    this.metricsService.pushMixpanelEvent('e-commerce Dispatch To Synchronize Info Clicked', {
      'Sale Channel Category': salesChannel.category,
      'Sale Channel Type': salesChannel.type,
      'Operation Date': operation.date,
      'Operation Id': operation.id,
    });
  }
}
